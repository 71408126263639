<template>

  <div class="page security_bg">
      <van-nav-bar title="安全检测"  right-text="我的订单"  @click-right="toMyOrder"></van-nav-bar>
      <img src="https://cym.bestweixiao.com/security_index.jpg" class="security_bg-top">
      <div class="security-block">
        <div class="security-block__title">汽车外观检查</div>
        <div class="car_appearance__item">
          <div class="left">
            <img src="../assets/car.png" style="width: 80px;height: 80px;" alt="">
          </div>
          <div class="right">
            <div class="index">1</div>
            <div class="content">观察车身外观无开裂，明显锈蚀和变形，车窗玻璃完好。
            </div>
          </div>
        </div>
        <div class="car_appearance__item">
          <div class="left">
            <img src="../assets/rearview_mirror.png" style="width: 56px;height: 38px;" alt="">
          </div>
          <div class="right">
            <div class="index">2</div>
            <div class="content">观察左右后视镜内后视镜完好。</div>
          </div>
         </div>

        <div class="car_appearance__item">
          <div class="left">
            <img src="../assets/front_mirror.png" style="width: 80px;height: 32px;" alt="">
          </div>
          <div class="right">
            <div class="index">3</div>
            <div class="content">前风玻璃刮水器开关和各档位正常。</div>
          </div>
        </div>
        <div class="car_appearance__item">
          <div class="left">
            <img src="../assets/water_box.png" style="width: 59px;height: 64px;" alt="">
          </div>
          <div class="right">
            <div class="index">4</div>
            <div class="content">检查发动机、水箱，无漏油、漏液现象。</div>
          </div>
        </div>
      </div>

      <div class="security-block">
        <div class="security-block__title">制动系统检查</div>
        <img src="../assets/zhidong_jiancha.png" style="width:157px;height:98px;margin:30px auto;display: block" alt="">
        <div class="zhidong">
          <div class="index_wrap">
            <div class="index">1</div>
          </div>

          <div class="content">观察各表盘是否正常、制动系统各故障指示灯是否正常</div>
        </div>
        <div class="zhidong">
          <div class="index_wrap">
            <div class="index">2</div>
          </div>
          <div class="content">检查制动系统的技术状况，排除链接 部位的松动、漏油或漏气现象。</div>
        </div>
        <div class="zhidong">
          <div class="index_wrap">
            <div class="index">3</div>
          </div>
          <div class="content">停车状态下起动发动机后，在发动机运转数分钟后踏下制动踏板反应是否正常。</div>
        </div>
        <div class="zhidong">
          <div class="index_wrap">
            <div class="index">4</div>
          </div>
          <div class="content">行驶状态下踩下制动踏板，汽车应迅速减速及停车，且无跑偏现象。
          </div>
        </div>
        <div class="zhidong">
          <div class="index_wrap">
            <div class="index">5</div>
          </div>
          <div class="content">当汽车行驶一段距离（不踩制动）后，停车检查制动鼓（制动盘和制动片）的温度时，温度应正常不烫手。</div>
        </div>
      </div>

      <van-button class="security-book-btn" @click="toBook" block round>预约检测</van-button>
  </div>
</template>

<script>
  import {Button, Notify,NavBar} from 'vant';
  import {isWeChatBrowser} from "../utils/browserUtil";
  import {TOKEN_KEY} from "../config";

  export default {
    name: "securityInspectIndex",
    components:{
      vanButton:Button,
      vanNavBar:NavBar
    },
    methods:{
      toMyOrder(){
        this.$router.push("/orders")
      },
      toBook(){
        this.$router.push("/group/security/inspection/book")
      },
      /**
       * 通过高德获取当前所在省市区
       */
      amapGeoCode(longitude, latitude) {
        const _this = this;
        this.$http.get(`https://restapi.amap.com/v3/geocode/regeo?key=0e845ebbb70f14b4c846e5fb0982048f&location=${longitude},${latitude}`)
          .then(resp => {
            if (resp.info === "OK") {
              console.log(resp.regeocode);
              const address = resp.regeocode.addressComponent;
              _this.$store.commit("CURRENT_CITY", {
                province:address.province,
                city:address.city,
                district:address.district,
                longitude,
                latitude,
                areaCode:address.adcode,
                formatAddress:resp.regeocode.formatted_address,

              })
            }
          })
      },
      getToken(){
        const {svcCode} = this.$route.query;
        this.$http.post("/picc/jiangsu/token",{
          svcCode
        }).then(resp => {
          if(resp.success){
            localStorage.setItem(TOKEN_KEY, resp.data.token);
          } else {
            Notify(resp.message);
          }
        })
      }

    },
    created() {
      const _this = this;
      const {svcCode} = this.$route.query;
      sessionStorage.setItem("svcCode", svcCode);
      this.getToken();
      if (isWeChatBrowser()) {
        this.$wechat.ready(() => {
          _this.$wechat.getLocation({
            success: function (res) {
              console.log(res);
              _this.amapGeoCode(res.longitude, res.latitude);
            },
            fail: function (error) {
              console.error(error);
            }
          })
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.security_bg-top{
  width: 100%;
  height: auto;
  background-size: cover;
}
  .security_bg{
    background: linear-gradient(127deg, #FF9C32, #FB8646);
  }
  .security-block{
    margin:30px auto;
    width: 316px;
    min-height: 300px;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 8px 8px 0px rgba(251, 108, 0, 0.6);
    position: relative;
    padding:10px;

    &__title{
      position: relative;
      height: 57.5px;
      width: 283px;
      background: url("../assets/security_title_bg.png") no-repeat;
      background-size:contain;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:-35px auto 0;
      color:#FFF;
      font-size: 16px;
      font-weight: 800;
    }
  }
  .car_appearance__item{
    display: flex;
    justify-content: space-between;
    align-items: center ;
    margin-bottom: 8px;
    .left{
      width: 70px;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 4px;
      flex:1;
    }
    .index{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #FDE9CE;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #784115;
    }
    .content{
      margin-left: 4px;
      background: #FDE9CE;
      border-radius: 5px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      padding:8px;
      box-sizing: border-box;
      color: #784115;
      flex:1;
    }
  }
  .zhidong{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .index_wrap{
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .index{
      width: 14px;
      height: 14px;
      background: #FFF1D4;
      border-radius: 14px;
      color: #784016;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content{
      font-size: 12px;
      font-weight: 400;
      margin-left: 10px;
      color: #784016;
      flex:1;
      line-height: 15px;
    }
  }

  .security-book-btn{
    position: fixed;
    bottom: 5%;
    left:50%;
    transform: translateX(-50%);
    width: 80%;
    background-color: #ECB946;
    color:#FFF;
    border:none;
    font-weight: 500;
  }
</style>
